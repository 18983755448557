import { SpacingName } from '@doveit/lego';
import React from 'react';
import { Service } from '../../../domain/service';
import { requireFromStaticAsset } from '../../utils/assetsUtils';
import { goToPage } from '../../utils/links';
import CardsHList from '../cards-hlist/CardsHList';
import Tile, { TileRatio } from '../tile/Tile';

export interface ServicesHListProps {
  services: Service[],
}

const ServicesHList: React.FC<ServicesHListProps> = ({ services }) => (
  <CardsHList
    sideSpacing={SpacingName.SMALL}
    inline={services.length}
    itemWidth="9rem"
  >
    {services.map((service) => (
      <CardsHList.Item
        key={service.id}
      >
        <Tile
          title={service.title}
          image={{
            src: requireFromStaticAsset(service.pictures.medium),
            alt: service.alt ?? service.title,
          }}
          ratio={TileRatio.W1_H1}
          onClick={service.urlObject ? () => goToPage(service.urlObject) : undefined}
          urlObject={service.urlObject}
          loading="lazy"
        />
      </CardsHList.Item>
    ))}
  </CardsHList>
);

export default ServicesHList;
